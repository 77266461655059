import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store'
import 'bootstrap/dist/js/bootstrap.bundle'
import '@/assets/scss/app.scss'
import '@vuepic/vue-datepicker/dist/main.css'
import VueFeather from 'vue-feather';
// import { createI18n } from 'vue-i18n'
// import en from './locales/en.json';
// import pt from './locales/fr.json';
//  import fr from './locales/pt.json';
// import es from './locales/es.json';
// import { defaultLocale, localeOptions } from './constants/config';
import Breadcrumbs from './components/bread_crumbs';
import '@/assets/css/custom.css';
import Toast, {POSITION} from "vue-toastification";
import "vue-toastification/dist/index.css";
import Multiselect from "vue-multiselect";
import { VueSignalR } from '@dreamonkey/vue-signalr';
import { HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr';
import {getPermissionByte} from "@/utils/helperFunctions";

const toastOptions = {
    position: POSITION.TOP_RIGHT
};
const baseURL = process.env.NODE_ENV === 'production' ? 'https://o2cportalbackend.azurewebsites.net' +
    '' : 'https://phoenixtestapi.azurewebsites.net';

const authInfo = localStorage?.getItem('auth_info') || '{}';
const user = JSON.parse(localStorage?.getItem('logged_user') || '{}');
const permission = getPermissionByte(user?.role);
console.log({permission})
// console.log(authInfo, 'authInfo authInfo authInfo')
const {tokenType, accessToken} = JSON.parse(authInfo);
// console.log(tokenType, accessToken, 'tokenType, accessToken tokenType, accessToken')

// const messages = { en: en, es: es, pt: pt, fr: fr};
//  const locale = (localStorage.getItem('currentLanguage') && localeOptions.filter(x => x.id === localStorage.getItem('currentLanguage')).length > 0) ? localStorage.getItem('currentLanguage') : defaultLocale;

const app = createApp(App);

app
.use(Toast, toastOptions)
.use(router)
.use(store)

if(permission > 2){
    const connection = new HubConnectionBuilder()
        .withUrl(`${baseURL}/notifications`, {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
            accessTokenFactory: () => accessToken
        })
        .build();

    app.use(VueSignalR, { connection })

}

app
.component(VueFeather.name, VueFeather)
.component('Breadcrumbs', Breadcrumbs)
.component('multiselect', Multiselect)
.mount('#app');
