import axios from "axios";
import {errorToast} from "@/utils/helperFunctions";
//https://phoenixdapidev.azurewebsites.net/api/v1
//https://phoenixtestapi.azurewebsites.net/api/v1
//https://bavariandevapi-b9f0btaeepb5fzdc.uksouth-01.azurewebsites.net/api/v1
const baseURL = process.env.NODE_ENV === 'production' ? 'https://o2cportalbackend.azurewebsites.net/api/v1' : 'https://phoenixtestapi.azurewebsites.net/api/v1';

const api = axios.create({
    baseURL,
    headers: {
        'accept': '*/*',
        'Content-Type': 'application/json',
    },
    customError: false
});

api.interceptors.request.use(
    config => {
        const authInfo = localStorage?.getItem('auth_info');
        if(authInfo){
            try {
                const {tokenType, expiresIn, accessToken} = JSON.parse(authInfo);
                // console.log({tokenType, expiresIn, accessToken})
                if(!tokenType || !expiresIn || !accessToken) throw new Error('Invalid auth storage');
                config.headers['Authorization'] = `${tokenType} ${accessToken}`;
            } catch (e) {
                console.log(e);
            }
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // console.log('interceptor error', error)
        if(error.response.status === 401){
            localStorage.clear();
            window.location.replace('/auth/login');
        }
        if(!error.response.config.customError){
            errorToast(error)
        }
        return Promise.reject(error);
    }
)

// app.provide('api', api)
export default api;
