import {separateOnCapitalLetter} from "@/utils/helperFunctions";
import {helpers, maxLength, minLength, numeric, required} from "@vuelidate/validators";
import PhoneCodes from "@/data/phoneCodes.json"
export const tablesDisplayInfo = {
    default:[
        'code',
        'description',
        'isDefault',
    ],
    PaymTerm:[
        'name',
        'numOfDays',
        'isDefault',
    ],
    PortalParameter:[
        'portalUrl',
        'stdCurrency',
        'stdUnitOfMeasure',
        'o2CFlashSales',
        'multipleCurrencyAllowed'
    ],
    UnitOfMeasure: [
        "symbol",
        "description",
    ],
    Country: [
        'name',
        'description',
        'isoCode',
    ],
    ZipCode:[
        "zipCode",
        "countryName",
        "stateName",
        "countyName",
        "cityName",
    ],
    InventLocation: [
        'name',
        'description',
        'siteName',
        'isDefault',
    ],
    InventSite: [
        'name',
        'description'
    ],
    City:[
        'name',
        'countryName',
        'stateName',
        'countyName',
    ],
    County: [
        'name',
        'countryName',
        'stateName',
    ],
    CustPaymMode: [
        'name',
        'description',
        'isDefault',
    ],
    CustGroup: [
        "name",
        "paymentTermName",
        'description',
        "isDefault",
    ],
    State: [
        'name',
        'description',
        'countryName',
    ],
    SalesPool: [
        "name",
        "description",
        "isDefault",
    ],
    ItemGroup: [
        "name",
        "description",
    ],
    Faq: [
        "title",
        "description",
        "createdOnUtc",
        "createdBy",
        "lastModifiedOnUtc",
        "lastModifiedBy",
    ],
    Currency: [
        "code",
        "description",
        "symbol"
    ],
    TaxItemGroup: [
        "itemVatGroup",
        "description",
        "vatCodes",
    ],
    TaxCustomerGroup: [
        "taxGroup",
        "description",
        "vatCodes",
    ],
    TradeAgreement: [
        "relationName",
        "description",
        "accountCodeName",
        "accountRelation",
        "itemCodeName",
        "itemRelation",
    ],
    DynamicsParameters: [
        "id",
        "dataAreaId",
        "authGrantType",
        "authClientId",
        "authClientSecret",
        "authResource",
        "authEndpoint",
        "vatEndpoint",
        "endpoint",
        "retryIntervalSeconds",
        "o2CDataAreaId",
    ],
    CustomerMasterDataDynamics: [
        "customerMasterDataId",
        "d365CustomerId",
        "dataAreaId",
        "isSuccess",
        "processedOnUtc",
        "createdBy",
        "createdOnUtc",
        "lastModifiedBy",
        "lastModifiedOnUtc",
        "error",
    ],
    SaleOrderHeaderDynamics: [
        "customerMasterDataId",
        "d365CustomerId",
        "saleOrderHeaderId",
        "d365OrderId",
        "dataAreaId",
        "isSuccess",
        "processedOnUtc",
        "createdBy",
        "createdOnUtc",
        "lastModifiedBy",
        "lastModifiedOnUtc",
        "error",
    ],
    VatCode: [
        "vatCode",
        "description",
        "percentage"
    ],
    SaleOrderHeader: [
        'id',
        'customerMasterDataId',
        'orderingCustomerAccountNumber',
        'createdBy',
        'createdByRole',
        'createdOnUtc',
        'd365OrderId',
        'statusName',
        'total',
    ],
    Kit: [
        'description',
        'masterProductId',
        'masterProductName',
        'validFromUtc',
        'validToUtc',
        'isActive',
        'childItemsNo',
    ]

}


const vatItemsTransform = (items) => {
    return items.map(data => {
        const {vats, ...rest} = data;
        const newData = rest;
        newData.vatCodes = '';
        newData.vatIds = vats.map(v => v.id);
        vats.forEach(vat => {
            newData.vatCodes +=`<div class="badge p-2 w-100 max-w-max badge-success">
                                        <div class="f-w-700">
                                            ${vat.vatCode} - ${vat.percentage}%
                                        </div>
                                    </div>`
        })

        return newData
    })
}
export const transformDisplayData = {
    TaxItemGroup: {
        transform: (items) => {
            return vatItemsTransform(items)
        }
    },
    TaxCustomerGroup: {
        transform: (items) => {
            return vatItemsTransform(items)
        }
    },
    Kit:{
        transform: (items) => {
            return items.map(item => {
                return {
                    ...item,
                    childItemsNo: item.childItems?.length || 0,
                    kitItems: item.childItems.map(child => {
                        return {
                            itemId: child.childItemId,
                            name: child.childItemName,
                            ...child
                        }
                    })
                }
            });
        }
    },
    SaleOrderHeader: {
        transform: (items) => {
            const orderStatus = {
                1: {title: 'Open', color: 'info'},
                2: {title: 'Shipped', color: 'success'},
                3: {title: 'Invoiced', color: 'primary'},
                4: {title: 'Canceled', color: 'danger'},
                5: {title: 'Partial Invoiced', color: 'light-primary'},
                6: {title: 'Pending', color: 'warning'}
            };

            return items.map(order => {
                const {title, color} = orderStatus[order.status];
                order.statusName = `<div class="badge p-2 w-100 max-w-max badge-${color}">
                                        <div class="f-w-700">
                                            ${title}
                                        </div>
                                    </div>`;
                return order;
            })
        }
    }
}

export const dateTypes = {
    createdOnUtc: {type: 'date'},
    lastModifiedOnUtc: {type: 'date'},
    processedOnUtc: {type: 'date'},
    validFromUtc: {type: 'date'},
    validToUtc: {type: 'date'},
}

export const customClass = {
    CustomerMasterDataDynamics: {
        error: 'textWrap'
    },
    SaleOrderHeaderDynamics: {
        error: 'textWrap'
    }
}

export const getDisplayInfo = (key) => {
    if(!tablesDisplayInfo[key]) key = 'default';
    return tablesDisplayInfo[key];
}


export const tableColumnTitles = {
    'name': 'Name',
    'vatGroup': 'Vat Group',
    'statusName': 'Status',
    'childItemsNo': 'Child Items No.',
    'id': 'Id',
    'orderingCustomerAccountNumber': 'D365 Customer ID',
    'title': 'Title',
    'createdOnUtc': 'Created On',
    'validToUtc': 'Valid To',
    'validFromUtc': 'Valid From',
    'lastModifiedOnUtc': 'Last Modified',
    'lastModifiedBy': 'Modified By',
    'code': 'Code',
    'description': 'Description',
    'isDefault': 'Default',
    'isoCode': 'Iso Code',
    'dataAreaId': 'Data Area Id',
    'countyCodeIt': 'County Code It',
    'inventLocations': 'Invent Locations',
    'countryName': 'Country',
    'stateName': 'State',
    'countyName': 'County',
    'numOfDays': 'Number of Days',
    'numofDays': 'Number of Days',
    'portalUrl': 'Portal Url',
    'stdCurrency': 'Currency',
    'stdUnitOfMeasure': 'Unit',
    'countryId': 'Country',
    'countyId': 'County',
    'createdBy': 'Created By',
    'cityId': 'City',
    'siteName': 'Site',
    'cityName': 'City',
    'stateId': 'State',
    'siteId': 'Invent Site',
    'zipCode': 'Zip Code',
    'paymentTermId': 'Payment Term',
    'paymentTermName': 'Payment Term',
    'multipleCurrencyAllowed': 'Multiple Currency',
    'accountCode': 'Account Code',
    'AccountRelation': 'Account Relation',
    'accountRelation': 'Account Relation',
    'itemCode': 'Item Code',
    'ItemRelation': 'Item Relation',
    'itemRelation': 'Item Relation',
    'unitOfMeasureId': 'Unit of Measure',
    'quantityAmountFrom': 'Quantity From',
    'quantityAmountTo': 'Quantity To',
    'percent1': 'Percent 1',
    'percent2': 'Percent 2',
    'relation': 'Relation',
    'currencyId': 'Currency',
    'amount': 'Amount',
    'customerMasterDataId': 'Customer ID',
    'isSuccess': 'Is Success',
    'processedOnUtc': 'Processed On',
    'vatIds': 'Vat Codes',
}

export const colTitle = (column, table) => {
    if(table === 'Order History' && column === 'id'){
        return 'Portal ID'
    }
    if(column === 'code' || column === 'name'){
        return table;
    }
    return tableColumnTitles[column] || separateOnCapitalLetter(column);
}

export const settings = [
    {title: 'Delivery mode', api: 'DlvMode', CUD: [1,1,1]},
    {title: 'Delivery term', api: 'DlvTerm', CUD: [1,1,1]},
    {title: 'Customer payment mode', api: 'CustPaymMode', CUD: [1,1,1]},
    {title: 'Payment terms', api: 'PaymTerm', CUD: [1,1,1]},
    {title: 'Portal Parameter', api: 'PortalParameter', CUD: [0,1,0]},
    {title: 'Invent Location', api: 'InventLocation', CUD: [1,1,1]},
    {title: 'Site', api: 'InventSite', CUD: [1,1,1]},
    {title: 'Country', api: 'Country', CUD: [1,1,1]},
    {title: 'County', api: 'County', CUD: [1,1,1]},
    {title: 'City', api: 'City', CUD: [1,1,1]},
    {title: 'State', api: 'State', CUD: [1,1,1]},
    {title: 'Sales Pool', api: 'SalesPool', CUD: [1,1,1]},
    {title: 'Item Group', api: 'ItemGroup', CUD: [1,1,1]},
    {title: 'Zip Code', api: 'ZipCode', CUD: [1,1,1]},
    {title: 'Customer Group', api: 'CustGroup', CUD: [1,1,1]},
    {title: 'FAQ', api: 'Faq', CUD: [1,1,1]},
    {title: 'Currency', api: 'Currency', CUD: [1,1,1]},
    {title: 'Tax Item Group', api: 'TaxItemGroup', CUD: [1,1,1]},
    {title: 'Tax Customer Group', api: 'TaxCustomerGroup', CUD: [1,1,1]},
    {title: 'Vat Code', api: 'VatCode', CUD: [1,1,1]},
    {title: 'Trade Agreement', api: 'TradeAgreement', CUD: [1,1,1]},
    {title: 'Dynamics Parameters', api: 'DynamicsParameters', CUD: [0,1,0]},
    {title: 'Unit of Measure', api: 'UnitOfMeasure', CUD: [1,1,1]},
]



export const cUSettings = {
    DlvMode: {
        code: {type: 'string', default: ''},
        description: {type: 'string', default: ''},
        isDefault: {type: 'boolean', default: false}
    },
    DlvTerm: {
        code: {type: 'string', default: ''},
        description: {type: 'string', default: ''},
        isDefault: {type: 'boolean', default: false}
    },
    CustPaymMode:{
        'name': {type: 'string', default: ''},
        'description': {type: 'string', default: ''},
        'isDefault': {type: 'boolean', default: false},
    },
    PaymTerm: {
        "name": {type: 'string', default: ''},
        "numOfDays": {type: 'string', default: ''},
        "isDefault": {type: 'boolean', default: false}
    },
    Country: {
        "name": {type: 'string', default: '', validation: {minLength: minLength(3), maxLength: maxLength(3),}},
        "description": {type: 'string', default: ''},
        "isoCode": {type: 'string', default: ''}
    },
    State: {
        "name": {type: 'string', default: ''},
        "description": {type: 'string', default: ''},
        "countryId": {type: 'select', default: '', api: 'Country', valueType: 'number', displayKey: 'description', params: {isDescending: false, columnName: 'description'}}
    },
    County: {
        "countryId": {type: 'select', default: '', api: 'Country', valueType: 'number', displayKey: 'description', params: {isDescending: false, columnName: 'description'}},
        "stateId": {type: 'select', default: '', api: 'State', relation: 'countryId', valueType: 'number', params: {isDescending: false, columnName: 'description'}},
        "name": {type: 'string', default: '', relation: 'stateId'},
    },
    InventLocation: {
        'name': {type: 'string', default: ''},
        "description": {type: 'string', default: ''},
        'siteId': {type: 'select', default: '', api: 'InventSite', valueType: 'number'},
        'isDefault': {type: 'boolean', default: false},
    },
    InventSite: {
        "name": {type: 'string', default: ''},
        "description": {type: 'string', default: ''},
        // "isoCode": {type: 'string', default: ''}
    },
    City: {
        "name": {type: 'string', default: ''},
        "countryId": {type: 'select', default: '', api: 'Country', valueType: 'number', displayKey: 'description', params: {isDescending: false, columnName: 'description'}},
        "stateId": {type: 'select', default: '', api: 'State', relation: 'countryId', valueType: 'number', params: {isDescending: false, columnName: 'description'}},
        "countyId": {type: 'select', default: '', api: 'County', relation: 'stateId', valueType: 'number', params: {isDescending: false, columnName: 'name'}}
    },
    PortalParameter: {
        "portalUrl": {type: 'string', default: ''},
        "stdCurrency": {type: 'string', default: ''},
        "stdUnitOfMeasure": {type: 'string', default: ''},
        "o2CFlashSales": {type: 'boolean', default: false},
        "multipleCurrencyAllowed": {type: 'boolean', default: false},
    },
    SalesPool: {
        "name": {type: 'string', default: ''},
        "description": {type: 'string', default: ''},
        "isDefault": {type: 'boolean', default: false},
    },
    ItemGroup: {
        "name": {type: 'string', default: ''},
        "description": {type: 'string', default: ''},
    },
    ZipCode: {
        "zipCode": {type: 'string', default: ''},
        "countryId": {type: 'select', default: '', api: 'Country', valueType: 'number', displayKey: 'description', params: {isDescending: false, columnName: 'description'}},
        "stateId": {type: 'select', default: '', api: 'State', relation: 'countryId', valueType: 'number', params: {isDescending: false, columnName: 'description'}},
        "countyId": {type: 'select', default: '', api: 'County', relation: 'stateId', valueType: 'number', params: {isDescending: false, columnName: 'name'}},
        "cityId": {type: 'select', default: '', api: 'City', relation: ['countyId', 'stateId', 'countryId'], valueType: 'number', params: {isDescending: false, columnName: 'name'}}
    },
    CustGroup: {
        "name": {type: 'string', default: ''},
        "description": {type: 'string', default: ''},
        "paymentTermId": {type: 'select', default: '', api: 'PaymTerm', valueType: 'number'},
        "isDefault": {type: 'boolean', default: false},
    },
    Faq: {
        "title": {type: 'string', default: ''},
        "description": {
            type: 'textarea',
            default: '',
            textareaOpts: {
                rows: 5,
                cols: 70
            }
        },
    },
    TradeAgreement: {
        "relation": {type: 'select', default: '', api: 'TradeRelation', valueType: 'number'},
        "accountCode": {type: 'select', default: '', api: 'AccountCode', valueType: 'number'},
        "accountRelation": {type: 'select', default: '', api: 'AccountRelation', validation: {
            custom: helpers.withMessage('Value is required', (value, {settingsData: {accountCode}}) => {
                if(accountCode && accountCode > 1) {
                    return !!value;
                }
                return true;
            })
        }},
        "itemCode": {type: 'select', default: '', api: 'ItemCode', valueType: 'number'},
        "itemRelation": {type: 'select', default: '', api: 'ItemRelation', validation: {
                custom: helpers.withMessage('Value is required', (value, {settingsData: {itemCode}}) => {
                    if(itemCode && itemCode > 1) {
                        return !!value;
                    }
                    return true;
                })
            }},
        "description": {type: 'string', default: ''},
        "amount": {type: 'number', default: '', valueType: 'number'},
        // "percent1": {type: 'number', default: 0, valueType: 'number', validation: {numeric}, notRequired: true},
        // "percent2": {type: 'number', default: 0, valueType: 'number', validation: {numeric}, notRequired: true},
        "priceUnit": {type: 'number', default: 1, valueType: 'number', validation: {numeric}, notRequired: true},
        "fromDate": {type: 'date', default: ''},
        "toDate": {type: 'date', default: ''},
        "quantityAmountFrom": {type: 'number', default: '', valueType: 'number'},
        "quantityAmountTo": {type: 'number', default: '', valueType: 'number'},
        "currencyId": {type: 'select', default: '', api: 'Currency', valueType: 'number', displayKey: 'code'},
        "unitOfMeasureId": {type: 'select', default: '', api: 'UnitOfMeasure', displayKey: 'symbol', valueType: 'number'},
    },
    DynamicsParameters: {
        "id": {type: 'null', default: ''},
        "dataAreaId": {type: 'null', default: ''},
        "authGrantType": {type: 'string', default: ''},
        "authClientId": {type: 'string', default: ''},
        "authClientSecret": {type: 'string', default: ''},
        "authResource": {type: 'string', default: ''},
        "authEndpoint": {type: 'string', default: ''},
        "endpoint": {type: 'string', default: ''},
        "vatEndpoint": {type: 'string', default: ''},
        "retryIntervalSeconds": {type: 'number', default: '', valueType: 'number'},
        "o2CDataAreaId": {type: 'string', default: '', notRequired: true},
    },
    TaxItemGroup:{
        "itemVatGroup":{type: 'string', default: ''},
        "description":{type: 'string', default: ''},
        "vatIds": {type: 'multiselect', api: 'VatCode', valueType: 'number', optionLabel: 'vatCode'},
    },
    TaxCustomerGroup:{
        "taxGroup":{type: 'string', default: ''},
        "description":{type: 'string', default: ''},
        "vatIds": {type: 'multiselect', api: 'VatCode', valueType: 'number', optionLabel: 'vatCode'},
    },
    VatCode: {
        'vatCode': {type: 'string'},
        "description":{type: 'string', default: ''},
        "percentage": {type: 'number', default: '', valueType: 'number'},
    },
    Currency: {
        'code': {type: 'string', disableEdit: true},
        "description":{type: 'string', default: ''},
        "symbol":{type: 'string', default: ''},
    },
    UnitOfMeasure: {
        'symbol': {type: 'string', disableEdit: true},
        "description":{type: 'string', default: ''},
    },
    Kit: {
        "description": {type: 'string', default: ''},
        "masterProductId": {type: 'select', default: '', api: 'ProductMasterData', displayKey: 'name', valueType: 'number'},
        "kitItems": {
            type: 'multiselect',
            api: 'ProductMasterData/getChildItems',
            optionLabel: 'name',
            trackBy: 'itemId',
            col: 'col-md-12',
            valueTransform: (newValue) => {
                console.log(newValue, 'newValue newValue')
                return newValue
                //     .map(item => {
                //     return {
                //         id: item.id,
                //         // "numberOfChildItems": newValue.numberOfChildItems,
                //         // "numberOfMasterItems": newValue.numberOfMasterItems
                //     }
                // })
            }
        },
        "isActive": {type: 'boolean', default: false, col: 'col-md-12' },
        "validFromUtc": {type: 'date'},
        "validToUtc": { type: 'date'},
    }
}

//if an input will have different data based on other input selections
//dynamic relations come to rescue
export const dynamicRelations = {
    AccountRelation: {
        parentKey: 'accountCode',
        key: 'accountRelation',
        options: [
            {id: 1, type: 'null', default: null},
            {id: 2, type: 'select', default: '', api: 'CustGroup', selectKey: 'name' },
            {id: 3, type: 'select', default: '', api: 'CustomerMasterData'},
        ]
    },
    ItemRelation: {
        parentKey: 'itemCode',
        key: 'itemRelation',
        options: [
            {id: 1, type: 'null', default: null},
            {id: 2, type: 'select', default: '', api: 'ItemGroup', selectKey: 'name'},
            {id: 3, type: 'select', default: '', api: 'ProductMasterData/getAllProducts'},
        ]
    }
}
export const hardCodeApi = {
    PhoneCodes: PhoneCodes,
    TradeRelation: [
        {id: 1, key: 'SimplePrice', name: 'Simple Price'},
        {id: 2, key: 'Discount', name: 'Discount'},
    ],
    AccountCode: [
        {id: 1, key: 'All', name: 'All'},
        {id: 2, key: 'Group', name: 'Group'},
        {id: 3, key: 'Table', name: 'Table'},
    ],
    ItemCode: [
        {id: 1, key: 'All', name: 'All'},
        {id: 2, key: 'Group', name: 'Group'},
        {id: 3, key: 'Table', name: 'Table'},
    ],
    StatusOptions: [
        {id: 1, name: 'In Check', color: 'warning'},
        {id: 2, name: 'Checked Successfully', color: 'success'},
        {id: 3, name: 'Check Not Passed', color: 'danger'},
        {id: 4, name: 'Canceled', color: 'light-danger'},
        {id: 5, name: 'Closed', color: 'info'}
    ],
    Title: [
        'Ms',
        'Miss',
        'Mr',
        'Mrs',
    ],
    Validations: [
        {id: 1, name: 'Not Validated'},
        {id: 2, name: 'Validated'},
    ],
    D365LockOpts: [
        {id: 0, name: 'No'},
        {id: 1, name: 'Invoice'},
        {id: 2, name: 'All'},
        {id: 3, name: 'Payment'},
        {id: 4, name: 'Requisition'},
        {id: 5, name: 'Never'},
        {id: 6, name: 'Purchase Order'},
    ],
    Stock: [
        {id: false, name: 'In Stock'},
        {id: true, name: 'Out of Stock'}
    ]
}
